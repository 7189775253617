<template>
  <div>
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <div class="col-12">
        <h1>Third party authentication</h1>
        <h5>
          We were unable to sign you in. If this problem persists, please contact
          <b-link href="mailto:support@reducer.co.uk">support@reducer.co.uk</b-link>&nbsp;and
          include the contents of the address bar.
        </h5>
        <h5 class="mt-3">
          <router-link :to="{ name: 'signin' }">Go to sign-in</router-link>
        </h5>
      </div>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { EventBus } from "@/components/eventbus";
import CognitoAuth from "@/cognito/cognitoauth";
import Console from "@/console";

export default {
  //Used for authentication from Okta
  name: "TokenLanding",
  created: function () {
    this.urlParts = this.parseURL();
    Console.log(this.urlParts);

    if (this.urlParts.access_token && this.urlParts.id_token) {
      CognitoAuth.createUserSession(this.parseURL().access_token, this.parseURL().id_token, null);
      EventBus.$emit("user-signed-in");
    } else {
      this.error = true;
    }
  },
  data() {
    return {
      title: "Reducer",
      error: false,
      urlParts: {}
    };
  },
  methods: {
    parseURL() {
      var returnVal = {};
      var argString = location.hash;
      var argArray = argString.split("&");
      for (var i = 0; i < argArray.length; i++) {
        var nameVal = argArray[i].split("=");
        //strip the hash
        if (i == 0) {
          var name = nameVal[0];
          nameVal[0] = name.slice(1);
        }

        returnVal[nameVal[0]] = decodeURI(nameVal[1]);
      }
      return returnVal;
    }
  }
};
</script>